<template>
	<div class="main" :class="show ? 'h5' : ''">
		<div class="htop">
		<mtop />
		</div>
		<div class="banner">
				<img
            src="../assets/ximg/banner-dzkf@2x.png"
            alt="szzc"
            class="simg"
          />
		</div>
		<!-- <div class="save_head">
			<div class="banner">
				<div class="head_title">专业定制开发服务</div>
				<div class="head_tip">
					个性化定制专家 全方位满足您的开发需求
				</div>
			</div>
		</div> -->

		<div class="save_box1">
			<h1 class="box1_title">我们的定制开发服务</h1>
			<h2 class="box1_tip">量身定制应用系统，专业团队倾心打造</h2>
			<div class="box1_bottom">
				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/icon-qkl.png"
							alt=""
							srcset=""
						/>
					</div>
					<div class="title">区块链定制开发</div>
					<div class="conttxt">
						APP、元宇宙、交易所、NFT开发、链游等
					</div>
					<div class="conttxt">多产品制解决方案</div>
					<div class="btn" @click="toPage('/customizing')">
						立即定制
					</div>
				</div>

				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/icon-app.png"
							alt=""
							srcset=""
						/>
					</div>
					<div class="title">APP定制开发</div>
					<div class="conttxt">企业级原生APP定制开发</div>
					<div class="conttxt">为客户提供优质行业APP解决方案</div>
					<div class="btn" @click="toPage('/customizing')">
						立即定制
					</div>
				</div>

				<div class="card">
					<div class="img">
						<img
							src="../assets/ximg/icon-wechat.png"
							alt=""
							srcset=""
						/>
					</div>
					<div class="title">微信定制开发</div>
					<div class="conttxt">小程序、公众号、H5多渠道</div>
					<div class="conttxt">各行业微信定制业务</div>
					<div class="btn" @click="toPage('/customizing')">
						立即定制
					</div>
				</div>
			</div>
		</div>

		<div class="save_box2">
			<h1 class="box2_title">企业为何选择定制开发</h1>
			<h2 class="box2_tip">
				采用专业开发流程满足客户软件产品的不同功能定制
			</h2>
			<div class="case-tab">
				<div ref="myCase" class="swiper-container Gwidth" id="case">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 0 }"
								@click="current = 0"
							>
								私有化功能
							</p>
						</div>
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 1 }"
								@click="current = 1"
							>
								VIP专项服务
							</p>
						</div>
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 2 }"
								@click="current = 2"
							>
								多平台对接
							</p>
						</div>
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 3 }"
								@click="current = 3"
							>
								成熟项目管理
							</p>
						</div>
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 4 }"
								@click="current = 4"
							>
								快速高效交付
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="box2_bot">
				<div class="bot_item" v-if="current === 0">
					<div class="right">
						<div class="title">私有化功能</div>
						<div class="sinfo">
							为企业提供量身定制功能开发服务，采用专业开发流程满足客户软件产品的不同功能定制。从客户需求出发深度整合各类业务需求，以用户体验为核心，定制专属私有化功能，延展触发用户粘性。
						</div>
					</div>
					<div class="left">
						<img
							src="../assets/images/tp-syhgn.png"
							alt=""
							srcset=""
						/>
					</div>
				</div>
				<div class="bot_item" v-if="current === 1">
					<div class="right">
						<div class="title">VIP专项服务</div>
						<div class="sinfo">
							业内优质售前售后服务，一对一交流+了解+沟通+探讨，以客户需求为导向提供个性化私人订制开发服务。精确化运作，专业化服务，全程细致答疑，及时响应确保运营无忧，为您提供全方位高端专属服务。
						</div>
					</div>
					<div class="left">
						<img
							src="../assets/images/tp- VIP.png"
							alt=""
							srcset=""
						/>
					</div>
				</div>
				<div class="bot_item" v-if="current === 2">
					<div class="right">
						<div class="title">多平台对接</div>
						<div class="sinfo">
							可进行多接口定制打通，轻松实现应用数据对接开发，如业务系统、客服系统、生产系统、物流系统等多种业务需求相关联，实现数据集成，助力企业全面布局，为企业业务长线快速发展提供强有力的保障。
						</div>
					</div>
					<div class="left">
						<img
							src="../assets/images/tp-dptdj.png"
							alt=""
							srcset=""
						/>
					</div>
				</div>
				<div class="bot_item" v-if="current === 3">
					<div class="right">
						<div class="title">成熟项目管理</div>
						<div class="sinfo">
							基于数年的大量客户个性化醒目需求和开发经验，贴近客户个性化需求，服务周详。拥有成熟的全流程质量管理体系，实施监督项目进程，多维度保障项目质量和项目进度。
						</div>
					</div>
					<div class="left">
						<img
							src="../assets/images/tp-csxmgl.png"
							alt=""
							srcset=""
						/>
					</div>
				</div>
				<div class="bot_item" v-if="current === 4">
					<div class="right">
						<div class="title">快速高效交付</div>
						<div class="sinfo">
							自主研发的成熟系统框架，可控性强，流程步骤一目了然，项目高效率开发缩短交付周期，实施费用合理，保证产品稳定性和高拓展性。功能强大灵活，系统安全稳定、快速流畅，可更新迭代。
						</div>
					</div>
					<div class="left">
						<img
							src="../assets/images/tp-lsgxjf.png"
							alt=""
							srcset=""
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="save_box4">
			<h1 class="box4_title">定制服务流程</h1>
			<h2 class="box4_tip">按需定制，快速响应客户定制需求</h2>
			<div class="box4_bot">
				<div class="line">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求调研</div>
						<div class="tip2">01</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求分析</div>
						<div class="tip2">02</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">原型设计</div>
						<div class="tip2">03</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">产品设计</div>
						<div class="tip2">04</div>
					</div>
				</div>
				<div class="bank">
					<div class="zzimg">
						<img src="../assets/ximg/jt.png" alt="" />
					</div>
				</div>
				<div class="line resco">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">界面设计</div>
						<div class="tip2">05</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">程序编码</div>
						<div class="tip2">06</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">软件测试</div>
						<div class="tip2">07</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">打包发布</div>
						<div class="tip2">08</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="toPage('/customizing')">立即定制</div>
			</div>
		</div>

		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			// this.$router.replace({ path: "X3matrix" });
			return {
				current: 0,
				autoplay: true,
				index: 0,
				banbox: [],
				problem: [],
				umobile: "",
				buy: true,
				buynum: 1,
				show: false,
				shows: false,
				shloading: false,
				sqloading: false,
				list: [
					{
						title: "网站建设周期需要多久？",
						description:
							"一般做个企业官网建设，周期为10-15个工作日，功能定制型网站，根据定制要求，核算交付周期。",
					},
					{
						title: "网站建设不满意怎么办？",
						description:
							"网站设计师按要求出设计稿，初稿不满意，可根据贵司的要求修改，并且不限制修改次数，直到满意为止！",
					},
					{
						title: "你们可以移交源代码嘛？",
						description:
							"可以的，我司在核实完您的信息之后，承诺随时提供网站建设源码和FTP信息，FTP信息很重要，请您妥善保管。",
					},
					{
						title: "外地企业怎么和你们沟通?",
						description:
							"可以通过QQ、微信、电话、视频会议等进行沟通，无需担心，不管本地及外地，项目进行中都是在线沟通的！",
					},
					{
						title: "已经有域名了，需要提供给你们什么？",
						description:
							"您只需要提供域名解析权限或管理密码就可以，我公司技术会帮您解析好IP地址，完善服务器信息！",
					},
					{
						title: "产品图片是你们帮我处理吗？",
						description:
							"我公司会帮您处理产品图（20张以内），banner图设计3-5张，如有特殊需要，请联系一诺客服！",
					},
					{
						title: "企业网站建设要多少钱？",
						description:
							"一般企业网站建设而言，如果只是展示宣传，无特殊功能，1万五千元左右，如有特殊定制，功能需求等需另行核算。",
					},
					{
						title: "你们能做网站优化推广吗？",
						description:
							"SEO是重要的引流方式，我们有多种推广方式，网站优化，竞价排名，SEM等供您选择，少花钱多办事儿！",
					},
					{
						title: "网站、APP开发有合同吗？",
						description:
							"签订正规网站建设合同，开具发票，诚信合作。",
					},
					{
						title: "是否有其他隐形费用?",
						description:
							"重合同、守承诺，网站建设期间无任何隐形费用。",
					},
				],
			};
		},
		focus: {
			inserted: function (el, { value }) {
				console.log(el, { value });
				if (value) {
					el.focus();
				}
			},
		},

		components: {
			mtop,
			mbottom,
		},

		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
			this.initCase();
		},
		watch: {
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			this.getinfo();
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			outleft() {
				this.autoplay = true;
			},
			moveleft(val) {
				console.log(val);
				this.autoplay = false;
				this.index = val;
			},
			changeban(val) {
				console.log(val);
				this.index = val;
			},
			kefu() {
				//   console.log(1111);
				//    window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
				// _MICHAT("accountid", 127020);
				// _MICHAT("domain", "mamkf.kanuomei.com");
				// (function (m, d, q, j, s) {
				//     j = d.createElement(q),s = d.getElementsByTagName(q)[0];
				//     j.async = true;
				//     j.charset ="UTF-8";
				//     j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "mamkf.kanuomei.com/Web/JS/mivisit.js?_=t";
				//     s.parentNode.insertBefore(j, s);
				// })(window, document, "script");
			},
			toPage(row) {
				this.$router.push(row);
			},
			getprice() {
				if (this.umobile == "") {
					this.$toast("请填写正确手机号!");
					return false;
				} else {
					this.$axios({
						method: "post",
						url: "/v1/apply",
						params: { mobile: this.umobile },
					})
						.then((res) => {
							console.log(res);
							if (res.data.code == 200) {
								this.$toast("成功提交");
								this.umobile = "";
							} else {
								this.$toast(res.data.msg);
							}
						})
						.catch((resp) => {
							this.$toast(resp.data.msg);
						});
				}
			},

			getinfo() {
				//   this.$axios({
				//     method:'get',
				//     url:'/v1/articleList',
				//     params:{type:2,cate_id:1,is_hot:1},
				// }).then((res)=>{
				//     this.list=res.data.data.list.data;
				// }).catch(resp => {
				//     console.log('请求失败');
				// });
			},
			to(e) {
				// this.$router.replace({ path: e });
				location.href = e;
			},
			tobuy() {
				location.href =
					"https://opensea.io/collection/cupid-love-metaverse";
			},

			close() {
				this.shows = false;
			},
			initCase() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#case", {
					loop: false,
					breakpoints: {
						320: {
							//当屏幕宽度大于等于320
							slidesPerView: 4,
						},
						750: {
							//当屏幕宽度大于等于768
							slidesPerView: 5,
						},
					},
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	$t-mf: "Arial";
	.case-tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #dcdee0;
		.case-nav {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #646566;
			padding: 10px 0;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #2b6bff;
				border-bottom: 1px solid #2b6bff;
			}
		}
	}
	.main {
		background: #fff;
		max-width: 100%;
		min-width: 1200px;
		width: 100%;
		.htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
		.save_head {
			cursor: default;
			height: 510px;
			background: url("../assets/ximg/banner.png");
			background-size: cover;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			.banner {
				width: 1200px;
				margin: 100px auto 10px;
			}
			.head_title {
				width: 1200px;
				font-size: 36px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #ffffff;
				line-height: 54px;
				text-align: left;
				padding-left: 40px;
			}
			.head_tip {
				width: 1200px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				margin-top: 23px;
				padding-left: 40px;
				text-align: left;
			}
		}
		.save_box1 {
			cursor: default;
			width: 100%;
			padding: 90px 0;
			background: #f4f6fe;
			display: flex;
			height: 702px;
			box-sizing: border-box;
			flex-direction: column;
			text-align: center;
			.box1_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0px;
			}
			.box1_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin: 0px;
				line-height: 50px;
			}
			.box1_bottom {
				display: flex;
				justify-content: space-between;
				width: 1200px;
				margin: 36px auto;
				.card {
					width: 363px;
					padding: 50px 0;
					height: 216px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow: hidden;
					.img {
						img {
							width: 90px;
							height: 90px;
						}
						margin-bottom: 35px;
					}
					.title {
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #1d2129;
						line-height: 33px;
						margin-bottom: 16px;
					}
					.conttxt {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #969799;
					}
					.btn {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2b6bff;
						line-height: 40px;
						width: 112px;
						height: 40px;
						background: #ffffff;
						border-radius: 2px;
						border: 1px solid #2b6bff;
						margin-top: 50px;
						display: none;
					}
				}
				.card:hover {
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					height: auto;
					transition: all 0.8s ease-in;
					.btn {
						display: block;
						cursor: pointer;
					}
				}
			}
		}
		.save_box2 {
			cursor: default;
			// width:1920px;
			min-height: 626px;
			background: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 90px 0;
			width: 1200px;
			margin: 0 auto;
			.box2_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0 auto;
				width: 100%;
				text-align: center;
			}
			.box2_tip {
				width: 100%;
				margin: 0 auto;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				text-align: center;
				line-height: 50px;
			}
			.box2_bot {
				display: flex;
				width: 1200px;
				margin: 20px auto;
				justify-content: space-between;
				flex-direction: column;
				.bot_item {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					margin: 22px 0;
					padding: 12px 73px;
					box-sizing: border-box;
					.left {
						img {
							width: 433px;
							height: auto;
						}
					}
					.right {
						width: 476px;
						.title {
							font-size: 24px;
							font-weight: 500;
							color: #1d2129;
							line-height: 33px;
							text-align: left;
							margin: 30px 0;
							position: relative;
							&::after {
								content: "";
								width: 22px;
								height: 4px;
								background: #2b6bff;
								border-radius: 2px;
								position: absolute;
								left: 0;
								bottom: -15px;
							}
						}
						.sinfo {
							font-size: 14px;
							font-weight: 400;
							color: #969799;
							text-align: left;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: row-reverse;
				}

				.item2 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fee2cd 0%,
							#fff2e7 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #8b3b34;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #8b3b34;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #8b3b34;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 20px;
						}
					}
				}

				.item3 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							92deg,
							#eff8ff 0%,
							#d0e7f9 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #0c63d7;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;

							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #0c63d7;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ffffff;
						border: 1px solid #0c63d7;
						color: #0c63d7;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #0c63d7;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #0c63d7;
							line-height: 20px;
						}
					}
				}

				.item4 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fec0ae 0%,
							#ffe0d7 100%
						);
						box-sizing: border-box;
						padding: 20px;
						color: #ff8460;
						div {
							color: #ff8460;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								font-size: 18px;
								color: #ff8460;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ff8460;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						a {
							color: #ffff;
							span {
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
		.save_box3 {
			cursor: default;
			width: 100%;
			height: 578px;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.box3_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				width: 1200px;
				margin: 0 auto;
			}
			.box3_con {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				width: 1200px;
				margin: 0 auto;
				line-height: 50px;
			}
			.box3_bot {
				width: 1200px;
				margin: 40px auto;
				padding: 0 30px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				.bot_item {
					width: 234px;
					height: 300px;
					background: #ffffff;
					box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.19);
					border-radius: 4px;
					border-bottom: 3px solid #0fb3b4;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;
					padding: 0 30px;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
					div:nth-child(2) {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
						margin-top: 40px;
						text-align: center;
					}
					div:nth-child(3) {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7e8184;
						line-height: 30px;
						text-align: left;
						margin-top: 27px;
						text-align: center;
					}
				}
				.you1 {
					border-bottom: 3px solid #0fb3b4;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
				}
				.you2 {
					border-bottom: 3px solid #8b3b34;
					div:nth-child(1) {
						color: #8b3b34;
					}
				}
				.you3 {
					border-bottom: 3px solid #16d1f1;
					div:nth-child(1) {
						color: #16d1f1;
					}
				}
				.you4 {
					border-bottom: 3px solid #ff8460;
					div:nth-child(1) {
						color: #ff8460;
					}
				}
				.bot_item:hover {
					color: #fff !important;
					background: linear-gradient(0deg, #1a8eff 0%, #19d5f5 100%);
					box-shadow: 0px 0px 21px 0px rgba(26, 150, 254, 0.19);
					border-radius: 10px;
					transform: scale(1.1);
					div {
						color: #fff !important;
					}
				}
			}
		}
		.save_box4 {
			height: 589px;
			background: #f4f6fe;
			padding: 1px;
			width: 100%;
			margin: 0 auto;
			.box4_title {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-top: 54px;
			}
			.box4_tip {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin-top: 15px;
			}
			.box4_bot {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 1200px;
				margin: 0 auto;
				margin-top: 25px;
				.line {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.jtico {
						width: 45px;
						height: 45px;
						margin: 0 11px;
					}
				}
				.resco {
					flex-direction: row-reverse;
					.jtico {
						transform: rotate(180deg);
					}
				}
				.bank {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					.zzimg {
						width: 249px;
						height: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					img {
						width: 45px;
						height: 45px;
						transform: rotate(90deg);
					}
				}
				.box_item {
					width: 249px;
					height: 75px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					position: relative;
					.img1 {
						width: 25px;
						height: 25px;
					}
					.tip1 {
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #1d2129;
						line-height: 27px;
						margin-left: 15px;
					}
					.tip2 {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 50px;
						font-family: DINPro-Bold, DINPro;
						font-weight: bold;
						color: #f7f8fa;
						line-height: 65px;
					}
				}

				.box_item:hover {
					transform: scale(1.02);
					box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
				}
			}
			.bottom {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 59px 0;
				text-align: center;
				.btn {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 40px;
					width: 112px;
					height: 40px;
					background: #2b6bff;
					border-radius: 2px;
					cursor: pointer;
				}
			}
		}
		.save_box5 {
			// max-height:520px;
			min-height: 300px;
			background: #fff;
			.box5_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 55px;
			}
			.box5-tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 13px;
			}
		}
		.box5_look {
			width: 100px;
			height: 34px;
			background: #ffffff;
			border: 1px solid #e9e9e9;
			border-radius: 17px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			line-height: 34px;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 35px;
			// margin-top:-38px;
		}
		.box5_look:hover {
			background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
			color: #fff;
		}
		.box5_bot {
			width: 1200px;
			margin: 0 auto;
			margin-top: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.box5_item {
				width: 590px;
				height: 95px;
				background: #ffffff;
				border: 1px solid #e9e9e9;
				margin-bottom: 20px;
				box-sizing: border-box;
				padding: 15px;
				text-align: left;
				div:nth-child(1) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				div:nth-child(2) {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 16px;
					margin-top: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
			.box5_item:hover {
				background: linear-gradient(0deg, #eee 0%, #fff 100%);
			}
		}
	}
	.h5 {
		width: 100%;
		min-width: 100%;
		.htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
		.save_head {
			.banner {
				width: 100%;
				margin: 0;
				.head_title {
					width: 100%;
					padding: 0 40px;
					box-sizing: border-box;
				}
				.head_tip {
					width: 100%;
					font-size: 26px;
					padding: 0 40px;
					box-sizing: border-box;
				}
			}
		}
		.save_box1 {
			width: 100%;
			height: auto;
			text-align: center;
			.box1_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box1_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box1_bottom {
				flex-direction: column;
				width: 100%;
				.card {
					width: calc(100% - 100px);
					height: auto;
					margin: 20px auto;
					.img {
						img {
							width: 200px;
							height: 200px;
						}
					}
					.title {
						font-size: 32px;
					}
					.conttxt {
						line-height: 36px;
						font-size: 26px;
					}
					.btn {
						width: 200px;
						height: 68px;
						font-size: 30px;
						line-height: 68px;
					}
				}
			}
		}
		.save_box2 {
			width: 100%;
			.box2_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box2_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box2_bot {
				width: 100%;
				.bot_item {
					flex-direction: column;
					padding-bottom: 40px;
					.left {
						img {
							width: calc(100% - 60px);
							height: 100%;
							margin: 0 30px;
						}
					}
					.right {
						.title {
							font-size: 32px;
						}
						.sinfo {
							font-size: 26px;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: column;
				}
			}
		}
		.save_box4 {
			width: 100%;
			height: 100%;
			.box4_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box4_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box4_bot {
				width: 100%;
				margin: 60px auto;
				.line {
					flex-direction: column;
					width: 80%;
					margin: 0 auto;
					.box_item {
						width: 100%;
						flex-direction: column;
						height: 100%;
						padding: 20px;
						border-radius: 10px;
						.tip1 {
							margin-left: 0;
							font-size: 26px;
						}
						.img1 {
							width: 66px;
							height: 66px;
							margin: 20px 0;
						}
					}
					.jtico {
						transform: rotate(90deg);
						margin: 20px;
						height: 100px;
						width: 100px;
					}
				}
				.bank {
					justify-content: center;
					.zzimg {
						height: 100px;
						margin: 20px;
						img {
							height: 100px;
							width: 100px;
						}
					}
				}
			}
			.bottom {
				.btn {
					width: 300px;
					height: 100px;
					line-height: 100px;
					font-size: 30px;
					border-radius: 10px;
				}
			}
		}
	}
</style>
